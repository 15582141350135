<!--
* @description airoad页面
* @fileName airoad.vue
* @author liulian
* @date 2023/07/17 15:40:16
-->
<template>
  <div class="airoad">
    <div class="a_top">
      <img class="a_t_bg" :src="$t(`vrRoad.banner`)" alt="banner" />
    </div>
    <div>
      <div class="product-title">{{ $t("vrRoad.section1_title") }}</div>
      <div class="introduce">
        <div class="left">{{ $t("vrRoad.section1_content1") }}</div>
        <div class="video">
          <video controls style="width: 100%; height: 100%">
            <source
              :src="
                this.$videBaseUrlNew +
                '2024-03-21/727c3c91-e9a9-490d-8618-1eff0adb01c2.mp4'
              "
              type="video/mp4"
            />
          </video>
        </div>
      </div>
    </div>
    <div class="">
      <img
        :src="$t(`vrRoad.${imgs[0].url}`)"
        alt=""
        style="width: 100%; max-width: 1920px"
      />
    </div>
    <div class="">
      <img
        :src="$t(`vrRoad.${imgs[1].url}`)"
        alt=""
        style="width: 100%; max-width: 1920px"
      />
    </div>
    <div class="">
      <img
        :src="$t(`vrRoad.${imgs[2].url}`)"
        alt=""
        style="width: 100%; max-width: 1920px"
      />
    </div>
    <div class="">
      <img
        :src="$t(`vrRoad.${imgs[3].url}`)"
        alt=""
        style="width: 100%; max-width: 1920px"
      />
    </div>
  </div>
</template>

<script>
export default {
  name: "airoad",
  components: {},
  data() {
    return {
      lists: [],
      imgs: [
        { url: "section1_img1" },
        { url: "section1_img2" },
        { url: "section1_img3" },
        { url: "section1_img4" },
      ],
      publicPath: process.env.BASE_URL,
    };
  },
  computed: {},
  created() {
    this.getInit();
  },
  mounted() {
    console.log("process.env.BASE_URL", process.env.BASE_URL);
  },
  methods: {
    getInit() {
      this.lists = [
        {
          cover: require("../../../assets/images/productImg/gnld_1@2x.png"),
          name: "方案布设快速  协同修改方便",
          con: "方案布设时通过简单的参数输入，实现自动布设，设计者可通过鼠标的点击和拖拽，就可以迅速的对相关方案进行编辑和修改，实现一键协同。",
        },
        {
          cover: require("../../../assets/images/productImg/gnld_2@2x.png"),
          name: "设计输入便捷",
          con: "支持多种地形数据的下载与上传，支持CAD图、JPG图片的导入等等，满足工可及方案研究地形及控制因素输入的要求。",
        },
        {
          cover: require("../../../assets/images/productImg/gnld_3@2x.png"),
          name: "输出的内容完备",
          con: "输出内容包括工可图纸、场景模型、漫游视频、数据文件、主要工程量和匡图、方案比选、方案展示及设计数据向详细设计阶段传递的要求。",
        },
        {
          cover: require("../../../assets/images/productImg/gnld_4@2x.png"),
          name: "多专业协同",
          key: "dzyxt",
          con: "方案布设时通过简单的参数输入，实现自动布设，设计者可通过鼠标的点击和拖拽，就可以迅速的对相关方案进行编辑和修改，实现一键协同。",
          children: [
            {
              name: "互通立交设计",
              con: "沿用了 EICAD模式法设计思路，并且可以实现自动拉坡，一键生成所有的立交端部实体。",
            },
            {
              name: "桥梁设计设计",
            },
            {
              name: "隧道设计",
            },
            {
              name: "交叉口路网设计",
            },
          ],
        },
      ];
    },
  },
};
</script>

<style scoped lang="scss">
.airoad {
  background: #ffffff;
  padding-bottom: 56px;
  .a_top {
    margin-bottom: 64px;
    .a_t_bg {
      width: 100%;
      height: 500px;
    }
  }
  .product-title {
    font-size: 36px;
    color: #1d1d1f;
    letter-spacing: 0;
    text-align: center;
    font-weight: 500;
    margin-bottom: 40px;
  }
  .introduce {
    display: flex;
    justify-content: center;
    align-items: center;
    .left {
      width: 493px;
      font-size: 18px;
      color: rgba(29, 29, 31, 0.8);
      letter-spacing: 2px;
      text-align: justify;
      line-height: 36px;
      font-weight: 400;
      margin-right: 85px;
    }
    .video {
      width: 640px;
      height: 320px;
    }
    margin-bottom: 40px;
  }
}
</style>
